$(function () {
	var
		body = $('body'),
		submenu = $('#submenu'),
		header = $('header');

	var submenuPos = submenu.position().top;

	$(window).on('load resize scroll', function () {
		var window_pos = $(window).scrollTop();

		if ((window_pos + header.outerHeight()) >= submenuPos) {
			submenu.css({
				'position': 'fixed',
				'width': '100%',
				'left': 0,
				'top': Math.ceil(header.outerHeight())
			});

			body.css({
				'margin-top': Math.ceil(submenu.outerHeight())
			});
		} else {
			submenu.css({
				'position': 'static'
			});
			body.css({
				'margin-top': 0
			});
		}
	});

	$('header .logo a').on('click', function (e) {
		e.preventDefault();

		var el = 'body';

		var loc = window.location.pathname;

		if (loc === '/' || loc === '/wp-content/themes/makeup/') {
			gotoElement(el, e);
		}
	});

	$('#needfor .item .title').on('click', function () {
		if ($(window).width() <= 767) {
			$(this).closest('.item').toggleClass('active');
		}
	});

	/* GoTo */
	$('.goto').on('click', function (e) {
		closeMobile();

		e.preventDefault();

		var href = $(this).attr('href');

		href = href.split('#');

		var el = '#' + href[1];

		if ($(this).data('open')) {
			$(el).addClass('active').siblings().removeClass('active');
		}

		gotoElement(el);
	});
	/* eo GoTo */


	/* Mobile menu */
	$('.burger').on('click', function () {
		$(this).toggleClass('active');
		$('body').toggleClass('locked');
		$('.mobile-menu').toggleClass('active');
	});
	$('.mobile-back').on('click', function () {
		closeMobile();
	});
	/* eo Mobile menu */


	/* Modal */
	$('body').on('click', '[data-modal]', function () {
		var
			modal_window = $(this).data('modal'),
			modal_value = $(this).data('value');

		var subtype = $(this).data('sub');
		if (subtype) {
			$('input[name="subtype"][value="' + subtype + '"]').prop('checked', true);
		}

		closeModals();

		if (modal_value) {
			$('#' + modal_window + ' .modal-what').html(modal_value).addClass('active');
			$('#' + modal_window + ' input[name="subject"]').val(modal_value);
		}

		showModal(modal_window);
	});

	$('.modal-close').on('click', function () {
		var modal_window = $(this).closest('modal-form').attr('id');
		closeModals();
		$('#' + modal_window).trigger('modal_window:off');
	});

	$('.modal-form').on('modal_window:on', function () {
		$(document).on('mouseup', function (e) {
			var container = $('.modal-form, #ui-datepicker-div');
			if (!container.is(e.target) && container.has(e.target).length === 0) {
				closeModals();
				container.trigger('modal_window:off');
			}
		});
	});

	$(document).keyup(function (e) {
		if (e.key === "Escape") { // escape key maps to keycode `27`
			closeModals();
		}
	});
	/* eo Modal */


	/* Form */
	/*
	$('.form-container').on('submit', function () {
		var	form = $('#' + $(this).attr('id'));

		// form.find('.btn').css({'visibility': 'hidden'});

		$.ajax({
			url: '',
			type: 'post',
			data: form.serialize(),
			dataType: 'json',
			success: function (json) {
				if (json.success) {
					form[0].reset();

					closeModals();

					Swal.fire(
						'Отправлено!',
						'В ближайшее время мы свяжемся с вами!',
						'success'
					);
				} else {
					Swal.fire(
						'Ошибка!',
						'Попробуйте еще раз!',
						'error'
					);
				}

				form.find('.btn').css({'visibility': 'visible'});
			}
		});

		return false;
	});
	/* eo Form */


	/* Masks */
	$('input[type="tel"]').inputmask('8 (999) 999-99-99');
	/* $('input[name="inn"]').inputmask('999999999999'); */
	/* eo Masks */


	/* Modal cookie */
	var rdnotify = $.cookie('rdnotify');

	if (rdnotify) {
		console.log(rdnotify);
		rdnotify = JSON.parse(rdnotify);
		$('#modal-cookie .cookie-message').html(rdnotify.text);

		showModal('modal-cookie');

		$.removeCookie('rdnotify');
	}
	/* eo Modal coo */

}); // eo ready

function closeMobile() {
	$('.burger, .mobile-menu').removeClass('active');
	$('body').removeClass('locked');
}

function showModal(id) {
	closeMobile();

	if ($('#' + id).length) {
		$('#modal-back').addClass('active');
		$('#' + id).addClass('active').trigger('modal_window:on');
		$('body').addClass('locked');
	}

	return false;
}

function closeModals() {
	$('#modal-back, .modal-form').removeClass('active');

	$('.modal-what, .cookie-message').html('').removeClass('active');

	$('input[name="subject"]').val('');

	$('body').removeClass('locked');

	return false;
}

function numberFormat(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

function gotoElement(el) {
	if ($(el).length) {
		var offset_top = $(el).offset().top;

		$('html, body').animate({
			scrollTop: offset_top - ($('header').outerHeight() + $('#submenu').outerHeight() + 10)
		}, 1000, 'easeInOutQuint');
	}
	return false;
}

function print_r(arr, level) {
	var print_red_text = "";
	if (!level) level = 0;
	var level_padding = "";
	for (var j = 0; j < level + 1; j++) level_padding += "    ";
	if (typeof (arr) == 'object') {
		for (var item in arr) {
			var value = arr[item];
			if (typeof (value) == 'object') {
				print_red_text += level_padding + "'" + item + "' :\n";
				print_red_text += print_r(value, level + 1);
			} else
				print_red_text += level_padding + "'" + item + "' => \"" + value + "\"\n";
		}
	} else print_red_text = "===>" + arr + "<===(" + typeof (arr) + ")";
	return print_red_text;
}