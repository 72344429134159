var rdajax = {};

rdajax.setup = {
    async: false, cache: false, headers: {
        'X-Requested-With': 'XMLHttpRequest'
    }
};

rdajax.httpRequest = function (url, params, headers) {
    $.ajaxSetup(rdajax.setup);
    var data = {};
    if (typeof params === 'undefined' || !params) {
        data.type = 'GET';
    } else {
        data.type = 'POST';
        data.data = 'json=' + encodeURIComponent(JSON.stringify(params));
    }
    if (typeof headers !== 'undefined') {
        data.headers = headers;
    }
    return $.ajax(url, data).responseText;
};

rdajax.process = function (data, form) {
    rdajax.cleanForm(form);
    try {
        var dataObj = $.parseJSON(data);
        if (typeof dataObj === 'undefined') {
            //noinspection ExceptionCaughtLocallyJS
            throw "data error";
        }
        if (typeof dataObj.actions === 'undefined') {
            dataObj.actions = [];
        }
        for (var key in dataObj.actions) {
            //noinspection JSUnfilteredForInLoop
            var action = dataObj.actions[key];
            switch (action.action) {
                case 'problem':
                    rdajax.problem(form, action.name);
                    break;
                case 'reload':		// refresh page
                    rdajax.reload();
                    break;
                default:
                    console.warn('rdajax action "' + action.action + '" is not implemented');
            }
        }
    } catch (err) {
        console.warn('Error: ', err.message);
        console.warn('Server returned:', data);
    }
};

rdajax.cleanForm = function (form) {
    $(form).find('.problem').removeClass('problem');
};

rdajax.reload = function () {
    window.location.reload();
};

rdajax.problem = function (form, name) {
    $(form).find('[name="' + name.replace(/"/g, "&quot;") + '"]:enabled').addClass('problem');
};

rdajax.sendForm = function (form, event) {
    var data = {
        form: $(form).serializeArray()
    };
    rdajax.process(this.httpRequest($(form).attr('action'), data), form);
};

$(document).on('submit', 'form.rdajax', function (event) {
    event.preventDefault();
    rdajax.sendForm(this, event);
});

$(document).on('focus, change', '.problem', function(event) {
    var target = $(event.target);
    if (target.prop('type') === 'radio') {
        var name = target.prop('name');
        target.closest('form').find('[name="' + name.replace(/"/g, "&quot;") + '"]').removeClass('problem');
    } else {
        target.removeClass('problem');
    }
});